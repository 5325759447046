import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Axios from 'axios';

function App() {

/*const URLwithAuth = "http://base2.tek-al.ru:31654/Dom/hs/Tours/V1/";
const URLwithoutAuth = "http://base2.tek-al.ru:31654/TfDom/hs/Tours/V1/";*/


let [Url, setUrl] = useState("http://base2.tek-al.ru:31654/Dom/hs/Tours/V1/");
let [Login, setLogin] = useState("hs");
let [Password, setPassword] = useState("ws");
let [Error, setError] = useState("");

let webapiInstance = Axios.create(
    {
        baseURL: Url,
        timeout: 5000,
        auth:{
            username: Login,
            password: Password
        }
    }
)

let getData = (bronId = -1) => {
        
  return webapiInstance.get(bronId != -1 ? `${bronId}` : `New`).then(res => {
      
          if (res.data)
              if (res.data["Результат"] == "Ok")
                  return({...res.data, statusCode: 0, statusMessage: ""})
              else
                  return({ statusCode: 1, statusMessage: res.data["ТекстСообщения"] })
      })
      .catch(
          err => {
              
              return { statusCode: 1, statusMessage: "Ошибка загрузки данных с сервера " + err }
          }
      )
}



  let getUrl = (e) => {
    
    getData().then(resp => {
      
      if (resp.statusCode == 0)
        setError("code: " + resp.statusCode + " ;Результат: " + resp.Результат + "; Date: " + resp.Date);
      else
        setError("code: " + resp.statusCode + "; message: " + resp.statusMessage);
    }).catch(resp => {
      
      setError("code: " + resp.statusCode + "; message: " + resp.statusMessage);
    })
  }
  

  return (
    <div className="App">
      <p>Куда обращаемся</p>
      <input type="text" onChange={(e) => setUrl(e.currentTarget.value)} value={Url}></input>
      <p>Логин</p>
      <input type="text" onChange={(e) => setLogin(e.currentTarget.value)} value={Login}></input>
      <p>Пароль</p>
      <input type="text" onChange={(e) => setPassword(e.currentTarget.value)} value={Password}></input>
      <p>{`-> ${Url}  as ${Login}:${Password}`}</p>
      <input type="button" onClick={getUrl} value="GET"/>
      <p></p>
      <p>{`Результат: ${Error}`}</p>
    </div>
  );
}

export default App;
